import apiFn from 'common/services/api-service';

const api = eventId => apiFn(`/api/cms/v1/events/${eventId}/ars-results-customization`);
const apiResetDefaultColors = eventId =>
  apiFn(`/api/cms/v1/events/${eventId}/clear-results-customizations`);

export const get = eventId => {
  return api(eventId)
    .get('', {
      params: {
        ownerType: 'event',
      },
    })
    .then(response => response.data);
};

// TODO: Remove with FF is_product_modularization_enabled
export const getOld = eventId => get(eventId).then(res => res.data);

export const put = (eventId, data) => {
  return api(eventId).put('', data);
};

export const reset = eventId => {
  return apiResetDefaultColors(eventId).post('');
};
