import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { t } from 'ttag';

import HTTP404 from 'common/components/ErrorRoutes/HTTP404';
import Layout from 'common/components/ErrorRoutes/Layout';
import { isEnabled as isFlagEnabled } from 'common/state/flags';

import AudienceResponse from '@domains/AudienceResponse';
import Configuration from '@domains/Configuration';

import Details from './Details';

const Privacy = lazy(() => import(/* webpackChunkName: "privacy" */ '@domains/Privacy'));
const People = lazy(() => import(/* webpackChunkName: "people" */ '@domains/People'));
const Sessions = lazy(() => import(/* webpackChunkName: "sessions" */ '@domains/Sessions'));
const Companies = lazy(() => import(/* webpackChunkName: "company" */ '@domains/Company'));
const Documents = lazy(() => import(/* webpackChunkName: "document" */ '@domains/Documents'));
const Videos = lazy(() => import(/* webpackChunkName: "videos" */ '@domains/Videos'));
const Maps = lazy(() => import(/* webpackChunkName: "maps" */ '@domains/Maps'));
const ImageMap = lazy(() => import(/* webpackChunkName: "maps" */ '@domains/Maps/ImageMap'));
const GoogleMap = lazy(() => import(/* webpackChunkName: "maps" */ '@domains/Maps/GoogleMap'));
const Surveys = lazy(() => import(/* webpackChunkName: "surveys" */ '@domains/Surveys'));
const WidgetPages = lazy(() =>
  import(/* webpackChunkName: "widgetpages" */ '@domains/WidgetPages')
);
const WidgetPagesTemplates = lazy(() =>
  import(/* webpackChunkName: "widgetpages" */ '@domains/WidgetPages/Templates')
);
const WidgetPagesPreview = lazy(() =>
  import(/* webpackChunkName: "widgetpages" */ '@domains/WidgetPages/Preview')
);
const WidgetPagesDesigner = lazy(() =>
  import(/* webpackChunkName: "widgetpages" */ '@domains/WidgetPages/Designer')
);
const HomePage = lazy(() => import(/* webpackChunkName: "homepage" */ '@domains/HomePage'));
const Sections = lazy(() => import(/* webpackChunkName: "sections" */ '@domains/Sections'));
const Discussions = lazy(() =>
  import(/* webpackChunkName: "discussions" */ '@domains/Discussions')
);
const Studio = lazy(() => import(/* webpackChunkName: "studio" */ '@domains/Studio'));
const ActivityFeed = lazy(() =>
  import(/* webpackChunkName: "activityFeed" */ '@domains/ActivityFeed')
);
const BannerAd = lazy(() =>
  import(/* webpackChunkName: "bannerAd" */ '@domains/BannerAd').catch(error => {
    if (error.name !== 'ChunkLoadError') {
      throw error;
    }
    return {
      default: () => (
        <Layout
          scoped
          title={t`Ad Blocker Detected`}
          body={t`This page could not be loaded. Please disable ad blockers to access this page.`}
          support={false}
        />
      ),
    };
  })
);

const Settings = lazy(() => import(/* webpackChunkName: "settings" */ '@domains/Settings'));
const Badges = lazy(() => import(/* webpackChunkName: "badges" */ '@domains/Badges'));
const BadgeDesigner = lazy(() =>
  import(/* webpackChunkName: "badges" */ '@domains/Badges/Designer')
);
const AddBadge = lazy(() => import(/* webpackChunkName: "badges" */ '@domains/Badges/AddBadge'));
const BadgeTemplates = lazy(() =>
  import(/* webpackChunkName: "badges" */ '@domains/Badges/Templates')
);
// Delete BadgeTemplatesOld while removing the onsite_badge_printing FF
const BadgeTemplatesOld = lazy(() =>
  import(/* webpackChunkName: "badges" */ '@domains/Badges/TemplatesOld')
);
const BadgeOrder = lazy(() => import(/* webpackChunkName: "badges" */ '@domains/Badges/Order'));
const BadgeOrderSuccess = lazy(() =>
  import(/* webpackChunkName: "badges" */ '@domains/Badges/Order/Success')
);
const Gamification = lazy(() =>
  import(/* webpackChunkName: "gamification" */ '@domains/Gamification')
);
const LiveDisplay = lazy(() =>
  import(/* webpackChunkName: "liveDisplay" */ '@domains/LiveDisplay')
);
const Announcements = lazy(() =>
  import(/* webpackChunkName: "announcements" */ '@domains/Announcements')
);
const Onsite = lazy(() => import(/* webpackChunkName: "onsite" */ '@domains/Onsite'));
const LeadCapture = lazy(() =>
  import(/* webpackChunkName: "lead-capture" */ '@domains/LeadCapture')
);
const Integration = lazy(() =>
  import(/* webpackChunkName: "integration" */ '@domains/Integration')
);
const Analytics = lazy(() => import(/* webpackChunkName: "analytics" */ '@domains/Analytics'));
const Audit = lazy(() => import(/* webpackChunkName: "audit" */ '@domains/Audit'));
const CommunicationEmails = lazy(() =>
  import(/* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails')
);
const CommunicationEmailDetails = lazy(() =>
  import(/* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails/Details')
);
const SelectEmailTemplate = lazy(() =>
  import(
    /* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails/Templates/Selection/SelectEmailTemplate'
  )
);
const SelectTemplateDesign = lazy(() =>
  import(
    /* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails/Templates/Selection/SelectTemplateDesign'
  )
);
const EmailEditor = lazy(() =>
  import(
    /* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails/EmailEditor'
  )
);
const EmailAnalytics = lazy(() =>
  import(/* webpackChunkName: "Communications-analytics" */ '@domains/Communications/Analytics')
);

const RegistrationDetails = lazy(() =>
  import(/* webpackChunkName: "reg-settings" */ '@domains/Registration/Details')
);
// TODO: EXP-17450 - Remove once the website builder has been launched.
const RegistrationStudioOld = lazy(() =>
  import(/* webpackChunkName: "reg-studio" */ '@domains/Registration/StudioOld')
);
const RegistrationEmails = lazy(() =>
  import(/* webpackChunkName: "reg-emails" */ '@domains/Registration/Emails')
);
const RegistrationOrders = lazy(() =>
  import(/* webpackChunkName: "reg-orders" */ '@domains/Registration/Orders')
);
const RegistrationOrder = lazy(() =>
  import(/* webpackChunkName: "reg-order" */ '@domains/Registration/Orders/Details')
);
const ConfirmationEmailEditor = lazy(() =>
  import(
    /* webpackChunkName: "reg-confirmation-email" */ '@domains/Registration/ConfirmationEmailEditor'
  )
);
const RegistrationPromoCodes = lazy(() =>
  import(/* webpackChunkName: "reg-promo-codes" */ '@domains/Registration/PromoCodes')
);
const RegistrationPromoCode = lazy(() =>
  import(/* webpackChunkName: "reg-promo-code" */ '@domains/Registration/PromoCodes/Details')
);
const RegistrationForm = lazy(() =>
  import(/* webpackChunkName: "reg-form" */ '@domains/Registration/Form')
);
const RegistrationTickets = lazy(() =>
  import(/* webpackChunkName: "reg-tickets" */ '@domains/Registration/Tickets')
);
const RegistrationTicketDetails = lazy(() =>
  import(/* webpackChunkName: "reg-ticket" */ '@domains/Registration/Tickets/Details')
);
const WebsiteBuilder = lazy(() =>
  import(/* webpackChunkName: "website-builder" */ '@domains/WebsiteBuilder')
);
const WebsiteBuilderEditor = lazy(() =>
  import(/* webpackChunkName: "website-builder-editor" */ '@domains/WebsiteBuilder/PageEditor')
);
const SelectPageTemplate = lazy(() =>
  import(
    /* webpackChunkName: "website-builder" */ '@domains/WebsiteBuilder/Templates/SelectTemplate/SelectPageTemplate'
  )
);
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ '@domains/Dashboard'));
// we don't really need to lazy load following routes but I'm going to do it
// either way as it's an easy _route_
const Profile = lazy(() => import(/* webpackChunkName: "people" */ '@domains/People/Profile'));
const Session = lazy(() => import(/* webpackChunkName: "sessions" */ '@domains/Sessions/Session'));
const Company = lazy(() => import(/* webpackChunkName: "company" */ '@domains/Company/Profile'));
const Survey = lazy(() => import(/* webpackChunkName: "surveys" */ '@domains/Surveys/Details'));

const Routes = () => {
  const { path } = useRouteMatch();

  const isOnsiteBadgePrintingEnabled = useSelector(state =>
    isFlagEnabled(state, 'onsite_badge_printing')
  );

  return (
    <Switch>
      <Route path={[path, `${path}/pages/details`]} exact>
        <Details />
      </Route>
      <Route path={`${path}/privacy`} exact>
        <Privacy />
      </Route>
      <Route path={[`${path}/people`, `${path}/pages/people`]} exact>
        <People />
      </Route>
      <Route path={[`${path}/people/:peopleId`, `${path}/pages/people/:peopleId`]} exact>
        <Profile />
      </Route>
      <Route path={[`${path}/sessions/details/:sessionId`, `${path}/sessions/:sessionId`]}>
        <Session />
      </Route>
      <Route path={[`${path}/sessions`, `${path}/pages/sessions`]} exact>
        <Sessions />
      </Route>
      <Route path={[`${path}/companies`, `${path}/pages/companies`]} exact>
        <Companies />
      </Route>
      <Route path={[`${path}/companies/:companyId`, `${path}/pages/companies/:companyId`]} exact>
        <Company />
      </Route>
      <Route path={[`${path}/documents`, `${path}/pages/documents`]} exact>
        <Documents />
      </Route>
      <Route path={[`${path}/videos`, `${path}/pages/videos`]} exact>
        <Videos />
      </Route>
      <Route path={[`${path}/surveys`, `${path}/pages/surveys`]} exact>
        <Surveys />
      </Route>
      <Route path={[`${path}/surveys/:surveyId`, `${path}/pages/surveys/:surveyId`]} exact>
        <Survey />
      </Route>
      <Route path={`${path}/ars/settings`} exact>
        <AudienceResponse />
      </Route>
      <Route path={`${path}/designer-pages`} exact>
        <WidgetPages />
      </Route>
      <Route path={`${path}/designer-pages/:pageId/templates`} exact>
        <WidgetPagesTemplates />
      </Route>
      <Route path={`${path}/designer-pages/:pageId/templates/:templateId`} exact>
        <WidgetPagesPreview />
      </Route>
      <Route path={`${path}/designer-pages/:pageId`}>
        <WidgetPagesDesigner />
      </Route>
      <Route path={`${path}/event-app/home-page`} exact>
        <HomePage />
      </Route>
      <Route path={`${path}/event-app/sections`} exact>
        <Sections />
      </Route>
      <Route path={`${path}/event-app/discussions`} exact>
        <Discussions />
      </Route>
      <Route path={`${path}/event-app/activity-feed`} exact>
        <ActivityFeed />
      </Route>
      <Route path={`${path}/event-app/banner-ads`} exact>
        <BannerAd />
      </Route>
      <Route path={`${path}/event-app/studio`} exact>
        <Studio />
      </Route>
      <Route path={`${path}/event-app/settings`} exact>
        <Settings />
      </Route>
      <Route path={`${path}/badge-designer`} exact>
        <Badges />
      </Route>
      <Route path={`${path}/badge-designer/add-badge`} exact>
        <AddBadge />
      </Route>
      <Route path={`${path}/badge-designer/badge-order`} exact>
        <BadgeOrder />
      </Route>
      <Route path={`${path}/badge-designer/badge-order/success`} exact>
        <BadgeOrderSuccess />
      </Route>
      {isOnsiteBadgePrintingEnabled && (
        <Route path={`${path}/badge-designer/templates`} exact>
          <BadgeTemplates />
        </Route>
      )}
      <Route path={`${path}/badge-designer/:badgeId`} exact>
        <BadgeDesigner />
      </Route>
      {/* Remove this  route while removing the onsite_badge_printing FF */}
      <Route path={`${path}/badge-designer/:badgeId/templates`} exact>
        <BadgeTemplatesOld />
      </Route>
      <Route path={`${path}/gamification`} exact>
        <Gamification />
      </Route>
      <Route path={[`${path}/live-display`, `${path}/pages/live-display`]} exact>
        <LiveDisplay />
      </Route>
      {/* Remove check-in path when removing onsite_badge_printing FF */}
      <Route path={[`${path}/onsite/check-in`, `${path}/onsite`]} exact>
        <Onsite />
      </Route>
      <Route path={`${path}/onsite/lead-capture`} exact>
        <LeadCapture />
      </Route>
      <Route path={`${path}/website-builder`} exact>
        <WebsiteBuilder />
      </Route>
      <Route path={`${path}/website-builder/pages/:pageId/templates`} exact>
        <SelectPageTemplate />
      </Route>
      <Route path={`${path}/communications/emails`} exact>
        <CommunicationEmails />
      </Route>
      <Route path={`${path}/communications/announcements`} exact>
        <Announcements />
      </Route>
      <Route path={`${path}/communications/emails/:emailId`} exact>
        <CommunicationEmailDetails />
      </Route>
      <Route path={`${path}/communications/emails/:emailId/templates`} exact>
        <SelectEmailTemplate />
      </Route>
      <Route path={`${path}/communications/emails/templates/:emailDesignId/designs`} exact>
        <SelectTemplateDesign />
      </Route>
      <Route path={`${path}/communications/emails/templates/:emailDesignId/editor`} exact>
        <EmailEditor />
      </Route>
      <Route path={`${path}/communications/emails/:emailId/analytics`}>
        <EmailAnalytics />
      </Route>
      <Route path={[`${path}/integrations`, `${path}/pages/integrations`]} exact>
        <Integration />
      </Route>
      <Route path={`${path}/analytics`} exact>
        <Analytics />
      </Route>
      <Route path={`${path}/audit-log`} exact>
        <Audit />
      </Route>
      <Route path={`${path}/configuration`} exact>
        <Configuration />
      </Route>
      <Route path={`${path}/maps`} exact>
        <Maps />
      </Route>
      <Route path={`${path}/maps/:mapId/image`}>
        <ImageMap />
      </Route>
      <Route path={`${path}/maps/:mapId/google`}>
        <GoogleMap />
      </Route>
      {/* TODO: EXP-17450 - Remove once the website builder has been launched */}
      <Route path={`${path}/registration/studio`}>
        <RegistrationStudioOld />
      </Route>
      <Route path={`${path}/registration/email/edit/:emailId`}>
        <ConfirmationEmailEditor />
      </Route>
      <Route path={`${path}/registration/email`}>
        <RegistrationEmails />
      </Route>
      <Route path={`${path}/registration/orders`} exact>
        <RegistrationOrders />
      </Route>
      <Route path={`${path}/registration/orders/:orderId`}>
        <RegistrationOrder />
      </Route>
      <Route path={`${path}/registration/details`}>
        <RegistrationDetails />
      </Route>
      <Route path={`${path}/registration/promo-codes/:promoCodeId`}>
        <RegistrationPromoCode />
      </Route>
      <Route path={`${path}/registration/promo-codes`}>
        <RegistrationPromoCodes />
      </Route>
      <Route path={`${path}/registration/form`}>
        <RegistrationForm />
      </Route>
      <Route path={`${path}/registration/tickets`} exact>
        <RegistrationTickets />
      </Route>
      <Route path={`${path}/registration/tickets/:ticketId`}>
        <RegistrationTicketDetails />
      </Route>
      <Route path={`${path}/website-builder/details`}>
        <WebsiteBuilder />
      </Route>
      <Route path={`${path}/website-builder/templates/:pageDesignId/editor`} exact>
        <WebsiteBuilderEditor />
      </Route>
      <Route path={`${path}/overview`}>
        <Dashboard />
      </Route>
      <Route path="*">
        <HTTP404 scoped ctaText={null} />
      </Route>
    </Switch>
  );
};

export default Routes;
