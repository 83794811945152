/*  global EXP_GOOGLE_MAPS_MAP_ID */

import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { getFormattedAddress } from '@domains/Maps/useGoogleMaps';

// lat/lng of the center on Toronto by default
const DEFAULT_LATITUTE = 43.653226;
const DEFAULT_LONGITUDE = -79.38318429999998;

const Wrapper = styled.div`
  width: 100%;
  min-height: 350px;
`;

const SearchInput = styled.input`
  display: inline-block;
  width: 100%;
  border: 1px solid #bbb;
  border-radius: 3px;
  padding: 7px 10px;
  outline: none;
  transition: border 0.2s, box-shadow 0.2s;
`;

const fetchMarker = async (api, markerDetails) => {
  const { AdvancedMarkerElement } = await api.maps.importLibrary('marker');
  const advancedMarkerElement = new AdvancedMarkerElement(markerDetails);

  return advancedMarkerElement;
};

const useGoogleMaps = (global = window) => {
  const [googleMaps, setGoogleMaps] = useState();
  useEffect(() => {
    const interval = setInterval(() => {
      if (global.google) {
        clearInterval(interval);
        setGoogleMaps(global.google);
      }
    }, 500);
    return () => global.clearInterval(interval);
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.google]);

  return googleMaps;
};

// useMap hook is responsible for creating the Map, Autocomplete, Marker and infoWindow
// using the Google Maps API
// If an address is initially provided it also do a textSearch using the Google Maps API
// and center the place on the map
const useMap = (api, mapRef, autoCompleteRef, initAddress) => {
  const [map, setMap] = useState();
  const [address, setAddress] = useState({});
  const [marker, setMarker] = useState({});
  const [infoWindow, setInfoWindow] = useState();

  useLayoutEffect(() => {
    if (api) {
      const mapInstance = new api.maps.Map(mapRef, {
        center: { lat: DEFAULT_LATITUTE, lng: DEFAULT_LONGITUDE },
        mapTypeControl: false,
        streetViewControl: false,
        zoom: 13,
        mapId: EXP_GOOGLE_MAPS_MAP_ID,
      });

      setMap(mapInstance);

      const autocomplete = new api.maps.places.Autocomplete(autoCompleteRef);
      autocomplete.addListener('place_changed', () => {
        // eslint-disable-next-line no-param-reassign
        autoCompleteRef.value = getFormattedAddress(
          autocomplete.getPlace()?.name,
          autocomplete.getPlace()?.formatted_address
        );
        setAddress(autocomplete.getPlace());
        fetchMarker(api, {
          position: autocomplete.getPlace().geometry.location,
          map: mapInstance,
        }).then(advancedMarkerElement => setMarker(advancedMarkerElement));
      });
      const info = new api.maps.InfoWindow();
      info.close();
      setInfoWindow(info);
    }
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    if (initAddress && map) {
      const placesService = new api.maps.places.PlacesService(map);
      placesService.textSearch({ query: initAddress }, (results, status) => {
        if (status === api.maps.places.PlacesServiceStatus.OK) {
          const place = results[0];
          // eslint-disable-next-line no-param-reassign
          autoCompleteRef.value = getFormattedAddress(place?.name, place?.formatted_address);
          setAddress(place);
          fetchMarker(api, { position: place.geometry.location, map }).then(advancedMarkerElement =>
            setMarker(advancedMarkerElement)
          );
        }
      });
    }
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, initAddress, map]);

  return { map, address, marker, infoWindow };
};

const GoogleMap = ({ id, value = '', onChange, disabled = false }) => {
  const mapRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const googleMaps = useGoogleMaps();
  const [initAddress] = useState(value);
  const { map, address, marker, infoWindow } = useMap(
    googleMaps,
    mapRef.current,
    autoCompleteRef.current,
    initAddress
  );

  useEffect(() => {
    if (address.geometry && marker) {
      map.setCenter(address.geometry.location);
      if (address.geometry.viewport) {
        map.fitBounds(address.geometry.viewport);
      }
      infoWindow.setContent(
        `<div><strong>${address.name}</strong><br>${address.formatted_address}`
      );
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
      const formattedAddress = getFormattedAddress(address?.name, address?.formatted_address);
      // update the new address value for the parent component through callback
      onChange(formattedAddress);
    }
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, marker]);

  return (
    <Wrapper>
      <SearchInput
        id={id}
        disabled={disabled}
        ref={autoCompleteRef}
        onChange={event => {
          // If user wants to set the location to be empty, we need to update the form from here
          if (event?.target?.value === '') onChange('');
        }}
      />
      <div id="map" ref={mapRef} css="height: 350px; width: 100%; margin-top: 10px" />
    </Wrapper>
  );
};

GoogleMap.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default GoogleMap;
