import { Alert, Form, Input, Radio, Select, Space, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import Label from 'common/components/Label';
import useConfig from 'common/hooks/useConfig';

import FeatureContactSalesLink from '@components/FeatureContactSalesLink';
import { KNOWLEDGE_BASE_URL_FOR_CUSTOM_SENDER_EMAIL } from '@domains/Organization/EmailDomain/constants';
import { useEmailDomainList } from '@domains/Organization/EmailDomain/queries';
import usePermission, { READ_SENDER_EMAIL_DOMAINS } from '@hooks/usePermission';

import { Field, Description } from './StyledComponents';

const DisabledText = styled(Typography.Text)`
  && {
    cursor: pointer;
  }
`;

const GrowEmailSpace = styled(Space)`
  width: 100%;
  & > *:first-child,
  & > *:last-child {
    flex-grow: 1;
  }
`;

const RadioGroup = styled(Radio.Group)`
  display: block;
`;

const RadioContainer = styled.div`
  width: 100%;
  border: 1px solid ${props => (props.$selected ? '#1890ff' : '#d9d9d9')};
  padding: 16px;
`;

const EmailField = ({ event, isDisabled }) => {
  const { organizationId } = useParams();
  const { get: getConfig, loading: isConfigLoading } = useConfig();
  const canUseCustomOutboundEmailAddress =
    !isConfigLoading && getConfig('is_whitelabel_sender_email_domain_enabled') === '1';
  const useCustomOutboundEmailAddress = Form.useWatch('useCustomOutboundEmailAddress');
  const selectedDomain = Form.useWatch('customEmailDomain');
  const { loading: isLoadingAuth, authorized } = usePermission();
  const { data, isLoading } = useEmailDomainList(
    organizationId,
    {
      verificationStatus: 'verified',
    },
    !!canUseCustomOutboundEmailAddress && !isLoadingAuth && authorized(READ_SENDER_EMAIL_DOMAINS)
  );
  const selectTooltipRef = useRef();
  const lowerCaseEventShortcode = event?.shortcode?.toLowerCase();

  const eventEmailsBold = (
    <Typography.Text strong key="domain">
      event-emails.com
    </Typography.Text>
  );

  let currentIsUnverified = false;
  const [, currentDomain] = event?.outboundEmailAddress?.split('@') || [];
  const domainOptions = data?.data.map(({ name }) => ({ value: name, label: name })) || [];
  // If the current domain isn't in the list, it must be unverified, so we'll add it as disabled
  if (!isLoading && !!currentDomain && !data.data.find(({ name }) => name === currentDomain)) {
    currentIsUnverified = true;
    domainOptions.unshift({
      value: currentDomain,
      label: (
        <Tooltip
          title={t`This domain, ${currentDomain}, has been disconnected. Please reverify this domain to use it again.`}
          placement="top"
          getPopupContainer={() => selectTooltipRef.current}
        >
          {currentDomain}
          &nbsp;
          <DisabledText disabled>({t`unverified`})</DisabledText>
        </Tooltip>
      ),
      disabled: true,
    });
  }
  const selectedIsUnverified = !data?.data.find(({ name }) => name === selectedDomain);

  const hasEmailDomains = !isLoading && (domainOptions.length > 0 || currentIsUnverified);

  const form = Form.useFormInstance();
  useEffect(() => {
    if (useCustomOutboundEmailAddress && !canUseCustomOutboundEmailAddress) {
      // If the user has selected to use a custom email address, but the feature is disabled, we'll
      // reset the value to false
      form.setFieldsValue({ useCustomOutboundEmailAddress: false });
    }
  }, [useCustomOutboundEmailAddress, canUseCustomOutboundEmailAddress, form]);

  const manageDomainsLink = (
    <Link
      to={generatePath('/organization/:organizationId/email-domain', { organizationId })}
    >{t`You can manage your custom email domains here.`}</Link>
  );

  return (
    <>
      <Field shouldUpdate label={<Label>{t`Email To Send From`}</Label>}>
        <Description>
          {t`System emails like alert emails, missed chat message notification emails, or any other email from EventMobi will be sent to attendees from this address.`}
        </Description>
        <Field name="useCustomOutboundEmailAddress" noStyle>
          <RadioGroup disabled={isDisabled}>
            <RadioContainer $selected={!useCustomOutboundEmailAddress}>
              <Radio value={false}>
                {t`Send from ${lowerCaseEventShortcode}@event-emails.com`}
              </Radio>
            </RadioContainer>
            <RadioContainer $selected={useCustomOutboundEmailAddress}>
              <Radio value disabled={!canUseCustomOutboundEmailAddress || !hasEmailDomains}>
                {t`Send From a Custom Email Address`}
                {!canUseCustomOutboundEmailAddress && (
                  <>
                    <br />
                    {t`Personalize your emails by using a custom email address.`}{' '}
                    {<FeatureContactSalesLink featureName="Custom Email Domain" />}
                  </>
                )}
                {canUseCustomOutboundEmailAddress && !hasEmailDomains && (
                  <>
                    <br />
                    {jt`No email domains have been registered.`} {manageDomainsLink}
                  </>
                )}
              </Radio>
              {useCustomOutboundEmailAddress && (
                <Field
                  label={<Label isRequired>{t`Email Address`}</Label>}
                  $padding="8px 24px"
                  $marginBottom="0"
                >
                  <GrowEmailSpace>
                    <Field
                      noStyle
                      name="customEmailLocal"
                      rules={[
                        { required: true, message: t`Outbound Email Address is required.` },
                        {
                          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/,
                          message: t`Invalid email address.`,
                        },
                      ]}
                    >
                      <Input
                        disabled={
                          isDisabled || !canUseCustomOutboundEmailAddress || selectedIsUnverified
                        }
                      />
                    </Field>
                    @
                    <Field
                      noStyle
                      name="customEmailDomain"
                      rules={[{ required: true, message: t`Outbound Email Address is required.` }]}
                    >
                      <Select
                        loading={isLoading}
                        options={domainOptions}
                        disabled={isLoading || isDisabled || !canUseCustomOutboundEmailAddress}
                      />
                    </Field>
                  </GrowEmailSpace>
                  <div ref={selectTooltipRef} />
                  <Description>{manageDomainsLink}</Description>
                  <br />
                  <Description>
                    {t`Need Help?`}{' '}
                    <Typography.Link
                      href={KNOWLEDGE_BASE_URL_FOR_CUSTOM_SENDER_EMAIL}
                      target="_blank"
                    >{t`Learn about email sender guidelines here.`}</Typography.Link>
                  </Description>
                  {currentIsUnverified && (
                    <Alert
                      showIcon
                      type="warning"
                      style={{ marginTop: '8px' }}
                      message={jt`Domain has been disconnected. Emails will be sent from the default EventMobi ${eventEmailsBold} domain.`}
                    />
                  )}
                </Field>
              )}
            </RadioContainer>
          </RadioGroup>
        </Field>
      </Field>
    </>
  );
};

EmailField.propTypes = {
  event: PropTypes.shape({
    shortcode: PropTypes.string.isRequired,
  }),
  isDisabled: PropTypes.bool.isRequired,
};

export default EmailField;
