import { Typography, Form, Row, Col, Button, message, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';

import ImageUpload from 'common/components/ImageUpload';
import Label from 'common/components/Label';
import Loading from 'common/components/Loading';
import Modal from 'common/components/Modal';
import StickyFooter from 'common/components/StickyFooter';
import useConfig from 'common/hooks/useConfig';

import ColorPicker, { VALID_HEX_COLOR_REGEX } from '@components/ColorPicker';
import ChartPreview from '@domains/Surveys/Questions/ChartPreview';

import { CHART_TYPE_BAR, SPONSOR_BANNER_IMAGE_ID } from './constants';
import {
  useResetResponseDisplayMutation,
  useResponseDisplayMutation,
  useResponseDisplayQuery,
} from './queries';

const { Title: AntTitle, Text } = Typography;
const { useForm } = Form;

const Wrap = styled.div`
  margin: 0 auto 50px;
  padding-bottom: 86px;
`;

const Title = styled(AntTitle)`
  && {
    margin-bottom: 0px;
  }
`;

const Container = styled(Space)`
  padding: 24px 58px;
`;

const Colors = ({ dataSource }) => (
  <Row gutter={15}>
    {dataSource.map(item => (
      <Col key={item.name} span={8}>
        <Form.Item
          name={item.name}
          valuePropName="value"
          label={<Label>{item.label}</Label>}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: t`Invalid color`,
            },
            {
              pattern: new RegExp(VALID_HEX_COLOR_REGEX),
              message: t`Invalid color`,
            },
          ]}
        >
          <ColorPicker name={item.name} width="100%" />
        </Form.Item>
      </Col>
    ))}
  </Row>
);

Colors.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const Header = ({ title, text }) => (
  <Row>
    <Col span={24}>
      <Title level={5}>{title}</Title>
    </Col>
    <Col span={24}>
      <Text type="secondary">{text}</Text>
    </Col>
  </Row>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const useResponseDisplay = () => {
  const [isSaving, setIsSaving] = useState(false);
  const config = useConfig();
  const { data: responseDisplay, isLoading } = useResponseDisplayQuery();
  const { mutateAsync: saveMutation } = useResponseDisplayMutation();
  const { mutate: onReset, isMutating: isResetting } = useResetResponseDisplayMutation();

  const initialValues = {
    ...(responseDisplay?.data.reduce((acc, cur) => {
      acc[cur.name] = cur.value;
      return acc;
    }, {}) ?? {}),
    sponsorBannerImage: { id: config.get(SPONSOR_BANNER_IMAGE_ID) },
  };

  const onSave = useCallback(
    async ({ sponsorBannerImage, ...values }) => {
      try {
        setIsSaving(true);

        await Promise.all([
          config.set(SPONSOR_BANNER_IMAGE_ID, sponsorBannerImage.id),
          saveMutation({ data: responseDisplay.data, values }),
        ]);

        message.success(t`Successfully updated response display settings.`);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error(t`Error updating response display settings.`);
      } finally {
        setIsSaving(false);
      }
    },
    [config, responseDisplay, saveMutation]
  );

  return { initialValues, isLoading, onSave, isSaving, onReset, isResetting };
};

const ResponseDisplay = () => {
  const { eventId } = useParams();
  const { initialValues, isLoading, onSave, isSaving, onReset, isResetting } = useResponseDisplay();

  const [form] = useForm();

  const resetToDefaultColors = () => {
    Modal.confirm({
      title: t`Reset to Default Colors?`,
      content: t`This will reset the colors of Polls, Surveys and Ask a Question Result Screens. Changes will automatically be saved. Are you sure you want to continue?`,
      async onOk() {
        onReset(null, {
          onSuccess: () => {
            message.success(t`Successfully reset to default colors.`);
            // We need the form to render with the updated values before we reset
            setTimeout(() => form.resetFields());
          },
        });
      },
    });
  };

  return (
    <Wrap>
      {isLoading && <Loading />}
      {!isLoading && (
        <Form
          form={form}
          colon={false}
          initialValues={initialValues}
          onFinish={onSave}
          requiredMark={false}
        >
          <Container align="start" direction="vertical" size="large">
            <Header
              title={t`Result Screen`}
              text={t`Manage the default colors and display of your Polls, Surveys, and Ask a Question Result Screens. Default colors are applied to results pages that do not have customized colors.`}
            />
            <Row gutter={24}>
              <Col span={12}>
                <Colors
                  dataSource={[
                    { name: 'headerTextColor', label: t`Title Text` },
                    { name: 'headerBackgroundColor', label: t`Title Background` },
                  ]}
                />
                <Colors
                  dataSource={[
                    { name: 'bodyTextColor', label: t`Chart Text` },
                    { name: 'bodyBackgroundColor', label: t`Chart Background` },
                  ]}
                />
                <Space direction="vertical" size="large">
                  <Header
                    title={t`Option Colors`}
                    text={t`Set up to 10 colors for survey and live poll options. If there are more than 10 options, the colors will repeat in order.`}
                  />
                  <Colors
                    dataSource={[...Array(10).keys()].map(i => ({
                      name: `answerColor${i + 1}`,
                      label: `Option ${i + 1}`,
                    }))}
                  />
                </Space>
                <Form.Item
                  label={<Label>{t`Sponsor Banner`}</Label>}
                  labelCol={{ span: 24 }}
                  validateTrigger="onComplete"
                  trigger="onComplete"
                  name="sponsorBannerImage"
                >
                  <ImageUpload resourceType="events" resourceId={eventId} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <ChartPreview
                      chartType={CHART_TYPE_BAR}
                      colors={{
                        bodyBackgroundColor: getFieldValue('bodyBackgroundColor'),
                        bodyTextColor: getFieldValue('bodyTextColor'),
                        headerBackgroundColor: getFieldValue('headerBackgroundColor'),
                        headerTextColor: getFieldValue('headerTextColor'),
                      }}
                      selectOptions={[...Array(10).keys()].map(i => ({
                        optionText: `Option ${i + 1}`,
                        color: getFieldValue(`answerColor${i + 1}`),
                      }))}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Container>

          <StickyFooter>
            <Button type="primary" htmlType="submit" loading={isSaving}>{t`Save Settings`}</Button>
            <Button
              type="secondary"
              onClick={resetToDefaultColors}
              loading={isResetting}
            >{t`Reset to Default Colors`}</Button>
          </StickyFooter>
        </Form>
      )}
    </Wrap>
  );
};

export default ResponseDisplay;
