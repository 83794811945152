import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';

import HTTP404 from 'common/components/ErrorRoutes/HTTP404';
import HTTP500 from 'common/components/ErrorRoutes/HTTP500';
import Loading from 'common/components/Loading';
import useConfig from 'common/hooks/useConfig';
import { SIDE_NAV_PROPERTY } from 'common/hooks/useSideNavExpanded';
import { getCmsEvent as getFn } from 'common/services/events/api';
import { event as flagFn } from 'common/services/flags/api';
import { setConfigurations, setEvent, setCustomDomain } from 'common/state/event';
import { setEvent as setFlags, isEnabled as isFlagEnabled } from 'common/state/flags';

import { config as getConfigFn } from '@domains/Configuration/service';
import { get as getCustomDomainFn } from '@domains/Settings/CustomDomain/service';

import EventOld from './indexOld';
import Navigation, { useIsNoSideNavPath } from './Navigation';
import Routes from './Routes';

const Content = styled.div`
  overflow: auto;
  width: ${props => (props.showSideNav ? `calc(100% - var(${SIDE_NAV_PROPERTY}))` : '100%')};
`;

const organizationPath = `/organization/:organizationId`;

const useEvent = () => {
  const { organizationId, eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  // this is to make sure we don't render the page until we have the configs
  const { loading: loadingConfig } = useConfig();

  useEffect(() => {
    if (eventId === null || typeof eventId === 'undefined') {
      return;
    }

    if (organizationId === null || typeof organizationId === 'undefined') {
      return;
    }

    async function effect() {
      try {
        // clear up the data before loading
        dispatch([setEvent(), setConfigurations(), setFlags()]);

        const [{ data }, [defaultConfig, config], { data: flags }] = await Promise.all([
          getFn(organizationId, eventId),
          getConfigFn(eventId),
          flagFn(eventId),
        ]);

        let customDomain;
        const isCustomDomainEnabledConf = config.find(
          conf => conf.key === 'is_custom_domain_enabled'
        );
        if (isCustomDomainEnabledConf?.value === '1' && isCustomDomainEnabledConf?.expires_at) {
          customDomain = await getCustomDomainFn(eventId)
            .then(response => response.domainCnameKey)
            .catch(err => {
              const code = err.errors?.[0]?.code;
              if (code === 'custom_domain_not_found') {
                return null;
              }

              throw err;
            });
        }

        dispatch([
          setEvent(data),
          setConfigurations(defaultConfig, config),
          setFlags(flags),
          setCustomDomain(customDomain),
        ]);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    effect();
  }, [organizationId, eventId, dispatch]);

  return { loading: loading || loadingConfig, error, organizationId };
};

export default () => {
  const isProductModularizationEnabled = useSelector(state =>
    isFlagEnabled(state, 'is_product_modularization_enabled')
  );

  const { loading, error, organizationId } = useEvent();

  const showSideNav = !useIsNoSideNavPath();

  if (!isProductModularizationEnabled) {
    return <EventOld />;
  }

  if (loading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    if (error?.status === 400 || error?.status === 404) {
      return (
        <HTTP404
          scoped
          ctaText={t`Return to Organization`}
          ctaHref={generatePath(organizationPath, { organizationId })}
        />
      );
    }
    return <HTTP500 />;
  }

  return (
    <>
      {/*
        Some pages require more space; we hide the side nav and give them
        100% of the viewport.
      */}
      {showSideNav && <Navigation />}
      <Content $showSideNav={showSideNav}>
        {!error && (
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
        )}
      </Content>
    </>
  );
};
