import { Form, Switch as AntSwitch, Row, Col, Button, Space, Typography, message } from 'antd';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import StickyFooter from 'common/components/StickyFooter';
import useConfig from 'common/hooks/useConfig';

import {
  ALLOW_ATTENDEES_TO_CHANGE_THEIR_RESPONSES,
  SHOW_UNMODERATED_TEXT_ANSWERS,
} from './constants';

const { Text } = Typography;

const Wrap = styled.div`
  margin: 0 auto 50px;
  padding-bottom: 86px;
`;

const Switch = styled(AntSwitch)`
  margin-left: 50px;
`;

const SwitchWrapper = styled(Col)`
  padding-bottom: 20px;
`;

const Container = styled(Space)`
  padding: 24px 58px;
`;

const useSettings = () => {
  const [isSaving, setIsSaving] = useState(false);
  const config = useConfig();

  const initialValues = useMemo(
    () => ({
      allowAttendees: config.isEnabled(ALLOW_ATTENDEES_TO_CHANGE_THEIR_RESPONSES),
      showUnmoderatedText: config.isEnabled(SHOW_UNMODERATED_TEXT_ANSWERS),
    }),
    [config]
  );

  const onSave = async values => {
    try {
      setIsSaving(true);

      await Promise.all([
        config.set(ALLOW_ATTENDEES_TO_CHANGE_THEIR_RESPONSES, values.allowAttendees ? '1' : '0'),
        config.set(SHOW_UNMODERATED_TEXT_ANSWERS, values.showUnmoderatedText ? '1' : '0'),
      ]);

      message.success(t`Successfully updated settings.`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error(t`Error updating settings.`);
    } finally {
      setIsSaving(false);
    }
  };

  return { initialValues, onSave, isSaving };
};

const Settings = () => {
  const { initialValues, onSave, isSaving } = useSettings();

  return (
    <Wrap>
      <Form colon={false} initialValues={initialValues} onFinish={onSave} requiredMark={false}>
        <Container>
          <Col>
            <Row>
              <SwitchWrapper>
                <Text>{t`Allow attendees to change their responses`}</Text>
              </SwitchWrapper>
            </Row>
            <Row>
              <Col>
                <Text>{t`Show unmoderated text answers from live results by default`}</Text>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <SwitchWrapper>
                <Form.Item noStyle name="allowAttendees" valuePropName="checked">
                  <Switch data-testid="allow-attendees" />
                </Form.Item>
              </SwitchWrapper>
            </Row>
            <Row>
              <Col>
                <Form.Item noStyle name="showUnmoderatedText" valuePropName="checked">
                  <Switch data-testid="show-unmoderated-text" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Container>
        <StickyFooter>
          <Button type="primary" htmlType="submit" loading={isSaving}>{t`Save Settings`}</Button>
        </StickyFooter>
      </Form>
    </Wrap>
  );
};

export default Settings;
