import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { OWNER_TYPE } from './constants';
import { get as getFn, put as putFn, reset as resetFn } from './service';

const responseDisplayOptions = eventId => ({
  queryKey: ['events', String(eventId), 'audience-response', 'response-display-options'],
  queryFn: () => getFn(eventId),
});

export const useResponseDisplayQuery = () => {
  const { eventId } = useParams();

  return useQuery(responseDisplayOptions(eventId));
};

export const useResponseDisplayMutation = () => {
  const { eventId } = useParams();

  return useMutation({
    mutationFn: ({ data, values }) => {
      const payload = data.map(row => ({
        ...row,
        eventId,
        ownerType: OWNER_TYPE,
        value: values[row.name],
      }));

      return putFn(eventId, payload);
    },
  });
};

export const useResetResponseDisplayMutation = () => {
  const { eventId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => resetFn(eventId),
    onSuccess: async () => {
      // Awaiting so the reset button is disabled until the refetch finishes
      await queryClient.invalidateQueries(responseDisplayOptions(eventId).queryKey);
    },
  });
};
