import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Alert, Checkbox, DatePicker, Divider, Form, Input, message, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'ttag';

import Label from 'common/components/Label';
import Loading from 'common/components/Loading';
import UnstyledCollapse from 'common/components/UnstyledCollapse';

import { ERR_GATEWAY_CONN_EXISTS } from './constants';
import { useAddConnectionMutation, useSupportedGateways } from './queries';

const DATE_FORMAT = 'll';

const FIELD_TYPE = {
  String: 'string',
  Date: 'date',
  Boolean: 'boolean',
};

export const ADD_CONNECTION_FORM_ID = 'add-connection-form';

const MaskedInput = props => {
  const [mask, setMask] = useState(true);

  return (
    <Input
      {...props}
      style={{ WebkitTextSecurity: mask ? 'disc' : 'inherit' }}
      suffix={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
        <span
          className="anticon ant-input-password-icon"
          onClick={() => setMask(v => !v)}
          role="button"
        >
          {mask ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </span>
      }
    />
  );
};

const CredentialFieldItem = ({ id, name, type, safe = true, required = false }) => {
  const namePath = ['credentials', id];

  switch (type) {
    case FIELD_TYPE.Date:
      return (
        <Form.Item
          name={namePath}
          label={<Label isRequired={required}>{name}</Label>}
          rules={[
            { required, message: t`${name} is required` },
            { type: 'date', message: t`Invalid date` },
          ]}
        >
          <DatePicker format={DATE_FORMAT} placeholder="" style={{ width: '100%' }} />
        </Form.Item>
      );
    case FIELD_TYPE.Boolean:
      return (
        <Form.Item name={namePath} valuePropName="checked">
          <Checkbox>{name}</Checkbox>
        </Form.Item>
      );
    case FIELD_TYPE.String:
    default:
      return (
        <Form.Item
          name={namePath}
          label={<Label isRequired={required}>{name}</Label>}
          rules={[{ required, message: t`${name} is required` }]}
        >
          {safe ? <Input /> : <MaskedInput />}
        </Form.Item>
      );
  }
};

CredentialFieldItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([FIELD_TYPE.String, FIELD_TYPE.Date, FIELD_TYPE.Boolean]).isRequired,
  safe: PropTypes.bool,
  required: PropTypes.bool,
};

const ConnectionForm = ({ onSuccess }) => {
  const [form] = Form.useForm();
  const {
    data: supportedGateways,
    isLoading: isLoadingGateways,
    isError: isSupportedGatewaysError,
  } = useSupportedGateways();
  const { mutate: mutateAdd, isLoading: isAdding, error: addError } = useAddConnectionMutation();
  const selectedGatewayType = Form.useWatch(['type'], form);
  const selectedAuthModeType = Form.useWatch(['authModeType'], form);

  const { availableAuthModes, credentialFields } = useMemo(() => {
    const gateway = supportedGateways?.find(gtw => gtw.gatewayType === selectedGatewayType);
    const authModes = gateway?.authModes ?? [];

    const authMode = authModes.find(am => am.authModeType === selectedAuthModeType);

    return {
      availableAuthModes: authModes,
      credentialFields: authMode?.credentials ?? [],
    };
  }, [supportedGateways, selectedGatewayType, selectedAuthModeType]);

  // reset the auth mode and credentials when the gateway is changed
  useEffect(() => {
    let authModeType = null;

    // auto-select the auth mode if there is only one available
    if (availableAuthModes.length === 1) {
      authModeType = availableAuthModes[0].authModeType;
    }

    form.setFieldsValue({ authModeType, credentials: null });
  }, [form, selectedGatewayType, availableAuthModes]);

  const handleSubmit = useCallback(
    async values => {
      const payload = {
        name: values.name,
        type: values.type,
        authMode: values.authModeType,
        credentials: Object.fromEntries(
          // in a very rare case, where no credentials are present or required for an auth mode,
          // `values.credentials` will be `undefined`.
          // for example, may be in 'Test Gateway' where there might be no credentials
          Object.entries(values.credentials ?? {})
            // remove `null` values - necessary for date fields that are cleared after selection
            .filter(([, value]) => value !== null)
            .map(([key, value]) => {
              // return the value as string if it is a moment object
              if (moment.isMoment(value)) {
                return [key, value.format('YYYY-MM-DD')];
              }
              return [key, value];
            })
        ),
      };

      mutateAdd(
        { payload },
        {
          onSuccess: ({ data }) => {
            onSuccess(data);
            message.success(t`Successfully added connection!`);
          },
          onError: err => {
            const code = err.errors?.[0].code;
            if (code === ERR_GATEWAY_CONN_EXISTS) {
              form.setFields([
                {
                  name: 'name',
                  errors: [
                    t`A connection with this name already exists. Please enter a different name.`,
                  ],
                },
              ]);
            } else {
              Sentry.captureException(err);
            }
          },
        }
      );
    },
    [form, mutateAdd, onSuccess]
  );

  const addConnectionError = addError && addError.errors?.[0]?.code !== ERR_GATEWAY_CONN_EXISTS;

  if (isLoadingGateways) {
    return <Loading />;
  }

  if (isSupportedGatewaysError) {
    return <Alert showIcon type="error" message={t`Oops! Something went wrong.`} />;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      id={ADD_CONNECTION_FORM_ID}
      onFinish={handleSubmit}
      requiredMark={false}
      disabled={isAdding}
      onValuesChange={changedValues => {
        if (changedValues.authModeType) {
          form.setFieldsValue({ credentials: null });
        }
      }}
    >
      <UnstyledCollapse condition={addConnectionError}>
        <Alert
          style={{ marginBottom: '20px' }}
          showIcon
          type="error"
          message={t`Error adding connection. Please try again.`}
        />
      </UnstyledCollapse>
      <Form.Item
        name="name"
        label={<Label isRequired>{t`Name`}</Label>}
        rules={[{ required: true, message: t`Name is required` }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="type"
        label={<Label isRequired>{t`Gateway`}</Label>}
        rules={[{ required: true, message: t`Gateway is required` }]}
      >
        <Select
          placeholder={t`Select`}
          disabled={isAdding}
          options={(supportedGateways ?? []).map(gateway => ({
            value: gateway.gatewayType,
            label: gateway.name,
          }))}
        />
      </Form.Item>

      <Form.Item
        name="authModeType"
        label={<Label>{t`Auth Mode`}</Label>}
        dependencies={['type']}
        rules={[{ required: true, message: t`Auth Mode is required` }]}
      >
        <Select
          placeholder={t`Select`}
          disabled={isAdding || !selectedGatewayType}
          options={availableAuthModes.map(authMode => ({
            value: authMode.authModeType,
            label: authMode.name,
          }))}
        />
      </Form.Item>

      {credentialFields.length > 0 && <Divider />}
      {credentialFields.map(field => (
        <CredentialFieldItem key={field.id} {...field} />
      ))}
    </Form>
  );
};

ConnectionForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default ConnectionForm;
