import { useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';

import apiFn from 'common/services/api-service';

const themeApi = id =>
  apiFn(`/api/uapi/events/${id}/registration/theme`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

const previewApi = id =>
  apiFn(`/api/uapi/events/${id}/registration/preview-link`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

const formApi = id => {
  return apiFn(`/api/uapi/events/${id}/registration/form`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });
};

const testEmail = id => {
  return apiFn(`/api/uapi/events/${id}/registration/send-test-email`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });
};

const emailTemplate = id => {
  return apiFn(`/api/uapi/events/${id}/email-templates`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });
};

export const DATE_FORMAT = 'MMM D, YYYY';
export const TIME_FORMAT = 'h:mm a';
export const ACCESS_TYPE = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const transformDatetime = datetime => {
  const date = moment.utc(datetime).format('YYYY-MM-DD');
  const time = moment.utc(datetime).format('HH:mm');

  /*
    Because Moment objects are mutable, we need independent objects for the date
    and time. To allow accurate comparisons, dates need to have the same time and
    times need to have the same date.
  */
  return [moment.utc(date), moment.utc(time, 'HH:mm')];
};

export const isValidDateRange = (startDate, endDate) => startDate.isSameOrBefore(endDate);

export const isValidTimeRange = (startTime, endTime) => {
  // Extract the time portion "as is" and ignore the date part, to simplify the comparison.
  const startAsTimeOnly = moment(startTime.format(TIME_FORMAT), TIME_FORMAT);
  const endAsTimeOnly = moment(endTime.format(TIME_FORMAT), TIME_FORMAT);
  return startAsTimeOnly.isBefore(endAsTimeOnly);
};

export const prepareDatetime = (date, time, timezone) => {
  const str = date.format('YYYY-MM-DDT') + time.format('HH:mm:ss');
  const local = moment.tz(str, timezone);

  return moment.utc(local).toISOString();
};

export const prepare = (payload, timezone) => {
  const updatedPayload = {
    generalSettings: {
      ...payload.generalSettings,
      openPeriod: payload.startDate
        ? {
            startDatetime: prepareDatetime(payload.startDate, payload.startTime, timezone),
            endDatetime: prepareDatetime(payload.endDate, payload.endTime, timezone),
          }
        : null,
    },
    flowSettings: payload.flowSettings,
  };

  if (updatedPayload.generalSettings?.accessType === ACCESS_TYPE.PUBLIC) {
    updatedPayload.rsvpSettings = { enabled: false };
  } else if (updatedPayload.generalSettings?.accessType === ACCESS_TYPE.PRIVATE) {
    updatedPayload.flowSettings.requireEmailFirst.enabled = true;
  } else {
    // Do nothing. No access type has been defined yet.
  }

  if (!updatedPayload.flowSettings.displayTermsOfUse) {
    updatedPayload.flowSettings.mustAcceptTermsOfUse = false;
  }

  return updatedPayload;
};

// Registration Theme service functions
export const getTheme = async eventId => {
  return themeApi(eventId)
    .get(null, {
      params: { include: 'landing_page_theme' },
    })
    .then(response => response.data.data);
};

export const getButtonTheme = async eventId => {
  return themeApi(eventId)
    .get(null, {
      params: {
        include: 'widget_theme,widget_form_theme',
      },
    })
    .then(response => response?.data?.data);
};

export const patchTheme = async (eventId, params) => {
  return themeApi(eventId)
    .patch('', params)
    .then(response => response.data.data);
};

export const getPreviewLink = async eventId => {
  return previewApi(eventId)
    .get('')
    .then(response => response.data.data);
};

export const getFormItems = async eventId =>
  formApi(eventId)
    .get('')
    .then(response => response.data);

export const createSection = async (eventId, payload) =>
  formApi(eventId)
    .post('', payload)
    .then(response => response.data);

export const getSection = async (eventId, sectionId) =>
  formApi(eventId)
    .get(sectionId)
    .then(response => response.data);

export const updateSection = async (eventId, sectionId, payload) =>
  formApi(eventId)
    .patch(sectionId, payload)
    .then(response => response.data);

export const updateFormItems = async (eventId, payload, config) =>
  formApi(eventId)
    .patch('', payload, {
      ...(config?.disablePartialUpdates
        ? { headers: { 'x-nested-list-partial-updates': 'false' } }
        : {}),
    })
    .then(response => response.data);

// Confirmation Emails
export const sendTestEmail = async (eventId, params) => {
  return testEmail(eventId)
    .post('', params)
    .then(response => response.data.data);
};

export const getEmailTemplates = async (eventId, type) => {
  return emailTemplate(eventId)
    .get('', { params: { type } })
    .then(response => response.data.data);
};

export const getEmailTemplate = async (eventId, templateId) => {
  return emailTemplate(eventId)
    .get(templateId)
    .then(response => response.data.data);
};

export const updateEmailTemplate = async (eventId, templateId, params) => {
  return emailTemplate(eventId)
    .patch(templateId, params)
    .then(response => response.data.data);
};

export const createEmailTemplate = async (eventId, params) => {
  return emailTemplate(eventId)
    .post('', params)
    .then(response => response.data.data);
};

export const getTickets = eventId =>
  apiFn(`/api/uapi/events/${eventId}/ticket-types`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  })
    .get('')
    .then(response => response.data);

export const usePreviewTicket = eventId => {
  const { data: tickets } = useQuery({
    queryKey: ['email-preview-ticket', eventId],
    queryFn: () => getTickets(eventId),
  });

  const firstTicketName = tickets?.data?.[0]?.name;
  return firstTicketName;
};
