import { Button, Space, Table as AntTable } from 'antd';
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { t } from 'ttag';

import ShowMore from 'common/components/ShowMore';

import DomainStatusTag, {
  FAILED,
  NOT_STARTED,
  PENDING,
  VERIFIED,
} from '@components/DomainStatusTag';
import usePermission, { DELETE_CUSTOM_DOMAINS } from '@hooks/usePermission';

const Table = ({
  dataSource,
  loading,
  selectedRowKeys,
  onViewRecords,
  onDelete,
  onLinkEvents,
  onRowSelection,
}) => {
  const { authorized } = usePermission();

  const rowSelection = {
    columnWidth: 50,
    selectedRowKeys,
    onChange: onRowSelection,
  };

  const events = useMemo(
    () =>
      dataSource
        ? Object.values(
            dataSource?.reduce((acc, domain) => {
              domain.linkedEvents.forEach(event => {
                acc[event.id] = event;
              });
              return acc;
            }, {})
          )
        : [],
    [dataSource]
  );
  const redirectURLs = useMemo(
    () => [...new Set(dataSource?.map(({ rootDomainRedirectUrl: url }) => url))],
    [dataSource]
  );

  const columns = useMemo(
    () => [
      {
        title: t`Domain Name`,
        dataIndex: 'domainName',
        sorter: (a, b) => a.domainName.localeCompare(b.domainName),
      },
      {
        title: t`Linked Events`,
        dataIndex: 'linkedEvents',
        key: 'linkedEventId',
        filters: events?.map(({ id, code }) => ({ text: code, value: id })),
        onFilter: (value, record) => record.linkedEvents.some(event => event.id === value),
        render: linkedEvents => (
          <ShowMore
            items={linkedEvents.map(({ id, code: name }) => ({ id, name }))}
            isTag
            uppercase={false}
          />
        ),
      },
      {
        title: t`Redirect URL`,
        dataIndex: 'rootDomainRedirectUrl',
        render: url => (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        ),
        filters: redirectURLs?.map(url => ({ text: url ?? '[None]', value: url })),
        onFilter: (value, record) => record.rootDomainRedirectUrl === value,
      },
      {
        title: t`Created Date`,
        dataIndex: 'createdAt',
        sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
        render: date => format(parseISO(date), 'PP'),
      },
      {
        title: t`Verification Status`,
        dataIndex: 'verificationStatus',
        render: status => <DomainStatusTag status={status} />,
        filters: [
          {
            text: <DomainStatusTag status={NOT_STARTED} />,
            value: NOT_STARTED,
          },
          {
            text: <DomainStatusTag status={PENDING} animate={false} />,
            value: PENDING,
          },
          {
            text: <DomainStatusTag status={FAILED} />,
            value: FAILED,
          },
          {
            text: <DomainStatusTag status={VERIFIED} />,
            value: VERIFIED,
          },
        ],
        onFilter: (value, record) => record.verificationStatus === value,
      },
      {
        title: t`Action`,
        key: 'action',
        render: row => (
          <Space direction="vertical">
            {row.verificationStatus === VERIFIED && (
              <Button type="link" onClick={() => onLinkEvents(row)}>{t`Link Events`}</Button>
            )}
            <Button type="link" onClick={() => onViewRecords(row.id)}>{t`View Records`}</Button>
            {authorized(DELETE_CUSTOM_DOMAINS) && (
              <Button type="link" onClick={() => onDelete([row])} danger>{t`Delete`}</Button>
            )}
          </Space>
        ),
      },
    ],
    [events, redirectURLs, authorized, onLinkEvents, onViewRecords, onDelete]
  );

  return (
    <AntTable
      rowKey="id"
      dataSource={dataSource}
      loading={loading}
      pagination={false}
      columns={columns}
      rowSelection={authorized(DELETE_CUSTOM_DOMAINS) && rowSelection}
    />
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
  onViewRecords: PropTypes.func.isRequired,
  onLinkEvents: PropTypes.func.isRequired,
  onRowSelection: PropTypes.func.isRequired,
};

export default Table;
