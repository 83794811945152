function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "Badge Designer"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "Lead Capture"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "Onsite App"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "Registration"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "Event App"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "Live Display"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "Communications"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "Social Analytics"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "Website Builder"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import { t } from "ttag";
export var PremiumProductKeys;
(function(PremiumProductKeys) {
    PremiumProductKeys["BadgeDesigner"] = "badge_designer_enabled";
    PremiumProductKeys["LeadCapture"] = "is_lead_capture_enabled";
    PremiumProductKeys["OnsiteApp"] = "is_checkin_enabled";
    PremiumProductKeys["Registration"] = "is_registration_v2_enabled";
    PremiumProductKeys["EventApp"] = "is_eventspace_enabled";
    PremiumProductKeys["LiveDisplay"] = "is_livedisplay_enabled";
    PremiumProductKeys["Communications"] = "is_communications_enabled";
    PremiumProductKeys["SocialAnalytics"] = "is_social_monitoring_enabled";
    PremiumProductKeys["Website"] = "is_website_builder_enabled";
})(PremiumProductKeys || (PremiumProductKeys = {}));
export var PREMIUM_PRODUCTS = function() {
    var _obj;
    return _obj = {}, _defineProperty(_obj, PremiumProductKeys.BadgeDesigner, {
        label: t(_templateObject()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _defineProperty(_obj, PremiumProductKeys.LeadCapture, {
        label: t(_templateObject1()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _defineProperty(_obj, PremiumProductKeys.OnsiteApp, {
        label: t(_templateObject2()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _defineProperty(_obj, PremiumProductKeys.Registration, {
        label: t(_templateObject3()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _defineProperty(_obj, PremiumProductKeys.EventApp, {
        label: t(_templateObject4()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _defineProperty(_obj, PremiumProductKeys.LiveDisplay, {
        label: t(_templateObject5()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _defineProperty(_obj, PremiumProductKeys.Communications, {
        label: t(_templateObject6()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _defineProperty(_obj, PremiumProductKeys.SocialAnalytics, {
        label: t(_templateObject7()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _defineProperty(_obj, PremiumProductKeys.Website, {
        label: t(_templateObject8()),
        hubspotUrl: "",
        upsellUrl: "todo"
    }), _obj;
};
export var COLORS = {
    primary: "#6925C8",
    secondary: "#F9F0FF"
};
