import { Typography, Tabs } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import useTab from '@hooks/useTab';

import ResponseDisplay from './ResponseDisplay';
import Settings from './Settings';

const { Title: AntTitle, Text } = Typography;

const Wrap = styled.div`
  margin: 0 auto 50px;
  padding-bottom: 86px;
`;

const TitleAreaContainer = styled.div`
  padding: 45px 0 35px 0;
  margin-left: 45px;
`;

const Title = styled(AntTitle)`
  && {
    margin-bottom: 0px;
  }
`;

const TAB_RESPONSE_DISPLAY = 'response_display';
const TAB_SETTINGS = 'settings';
const TAB_DEFAULT = TAB_RESPONSE_DISPLAY;
const VALID_TABS = [TAB_RESPONSE_DISPLAY, TAB_SETTINGS];

const AudienceResponse = () => {
  const [activeTab, onChange] = useTab(TAB_DEFAULT, VALID_TABS);

  return (
    <Wrap>
      <TitleAreaContainer>
        <Title>{t`Audience Response`}</Title>
        <Text type="secondary">{t`Manage your default settings for Polls, Surveys, and Ask a Question across your event.`}</Text>
      </TitleAreaContainer>
      <Tabs
        type="card"
        activeKey={activeTab}
        onChange={onChange}
        defaultActiveKey={TAB_RESPONSE_DISPLAY}
        tabBarStyle={{ marginBottom: 0, paddingLeft: 45 }}
        destroyInactiveTabPane
        items={[
          {
            label: t`Response Display`,
            key: TAB_RESPONSE_DISPLAY,
            children: <ResponseDisplay />,
          },
          {
            label: t`Settings`,
            key: TAB_SETTINGS,
            children: <Settings />,
          },
        ]}
      />
    </Wrap>
  );
};

export default AudienceResponse;
