import { useCustomDomain, useVerifyCustomDomain } from './queries';

const useGetVerifyCustomDomain = (
  organizationId,
  domainId,
  viewMode,
  regenerateMode,
  refreshMode
) => {
  const { data: customDomainData, isFetching } = useCustomDomain(
    organizationId,
    viewMode ? domainId : null
  );
  const {
    data: verifyData,
    isRefetching,
    isInitialLoading,
    isRefetchError,
  } = useVerifyCustomDomain(
    organizationId,
    !viewMode || refreshMode || (viewMode && regenerateMode) ? domainId : null,
    regenerateMode
  );

  return {
    // verifyData will have value when we are creating a new custom domain and when we are regenrating the dns_records
    // In both cases we need to prioritize using verifyData
    // In normal view mode we will use customDomainData
    data: verifyData ?? (viewMode ? customDomainData : undefined),
    isLoading: isFetching,
    isRefetching: isRefetching || isInitialLoading,
    isRefetchError,
  };
};

export default useGetVerifyCustomDomain;
