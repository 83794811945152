import { useIsMutating } from '@tanstack/react-query';
import { Button, Modal, Space, Steps } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';

import ConnectionForm, { ADD_CONNECTION_FORM_ID } from './AddForm';
import EditForm, { EDIT_CONNECTION_FORM } from './EditForm';
import { addConnectionMutationOptions, updateConnectionMutationOptions } from './queries';

const STEP_ADD_CONNECTION = 0;
const STEP_LINK_EVENTS = 1;

const AddModal = ({ onClose }) => {
  const [gateway, setGateway] = useState(null);
  const { organizationId } = useParams();

  const isAdding = !!useIsMutating(addConnectionMutationOptions(organizationId));
  const isUpdating = !!useIsMutating(updateConnectionMutationOptions(organizationId));

  const currentStep = gateway?.id ? STEP_LINK_EVENTS : STEP_ADD_CONNECTION;

  return (
    <Modal
      title={t`Add Connection`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <Button onClick={onClose} disabled={isAdding || isUpdating}>
              {currentStep === STEP_ADD_CONNECTION ? t`Cancel` : t`Skip, I'll do it later`}
            </Button>
          </Space>
          <Space>
            {currentStep === STEP_ADD_CONNECTION && (
              <Button
                type="primary"
                htmlType="submit"
                form={ADD_CONNECTION_FORM_ID}
                loading={isAdding}
              >
                {t`Add Connection`}
              </Button>
            )}
            {currentStep === STEP_LINK_EVENTS && (
              <Button
                type="primary"
                htmlType="submit"
                form={EDIT_CONNECTION_FORM}
                loading={isUpdating}
              >
                {t`Link to Events`}
              </Button>
            )}
          </Space>
        </div>
      }
      width={700}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Steps
            size="small"
            current={currentStep}
            items={[{ title: t`Add Connection` }, { title: t`Link to Events` }]}
            style={{ width: 420 }}
          />
        </div>

        {currentStep === STEP_ADD_CONNECTION && <ConnectionForm onSuccess={setGateway} />}
        {currentStep === STEP_LINK_EVENTS && (
          <EditForm initialValues={gateway} onClose={onClose} onlyEditLinkedEvents />
        )}
      </Space>
    </Modal>
  );
};

AddModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddModal;
