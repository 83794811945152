import * as Sentry from '@sentry/react';
import { useIsMutating } from '@tanstack/react-query';
import { Alert, Form, Input, message, Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';

import Label from 'common/components/Label';
import Loading from 'common/components/Loading';
import UnstyledCollapse from 'common/components/UnstyledCollapse';

import { useEventsWithFeatureQuery } from '../queries';

import { ERR_GATEWAY_CONN_EXISTS } from './constants';
import {
  updateConnectionMutationOptions,
  useConnectedEventIdsQuery,
  useUpdateConnectionMutation,
} from './queries';

export const EDIT_CONNECTION_FORM = 'edit-connection-form';

const EditForm = ({ initialValues = null, onlyEditLinkedEvents = false, onClose }) => {
  const [form] = Form.useForm();
  const { mutate: mutateUpdate, error: updateConnectionError } = useUpdateConnectionMutation();
  const { organizationId } = useParams();

  const isUpdating = !!useIsMutating(updateConnectionMutationOptions(organizationId));

  const handleSubmit = async payload => {
    mutateUpdate(
      { id: initialValues.id, payload },
      {
        onSuccess: () => {
          onClose();
          if (onlyEditLinkedEvents) {
            message.success(t`Successfully linked events to the connection.`);
          } else {
            message.success(t`Successfully updated the connection.`);
          }
        },
        onError: err => {
          const code = err.errors?.[0].code;
          if (code === ERR_GATEWAY_CONN_EXISTS) {
            form.setFields([
              {
                name: 'name',
                errors: [
                  t`A connection with this name already exists. Please enter a different name.`,
                ],
              },
            ]);
          } else {
            Sentry.captureException(err);
          }
        },
      }
    );
  };
  const {
    data: allowedEvents,
    isLoading: isAllowedEventsLoading,
    isFetching: isAllowedEventsFetching,
    isError: isAllowedEventsError,
  } = useEventsWithFeatureQuery('reg_payments');
  const {
    data: connectedEventIds,
    isLoading: isConnectedEventIdsLoading,
  } = useConnectedEventIdsQuery();

  const isUpdateConnectionError =
    updateConnectionError && updateConnectionError.errors?.[0]?.code !== ERR_GATEWAY_CONN_EXISTS;

  if (isAllowedEventsLoading || isConnectedEventIdsLoading) {
    return <Loading />;
  }

  if (isAllowedEventsError) {
    return <Alert showIcon type="error" message={t`Oops! Something went wrong.`} />;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      id={EDIT_CONNECTION_FORM}
      initialValues={{
        id: initialValues?.id,
        name: initialValues?.name,
        events: initialValues?.events ?? [],
      }}
      onFinish={handleSubmit}
      requiredMark={false}
      disabled={isUpdating}
    >
      <UnstyledCollapse condition={isUpdateConnectionError}>
        <Alert
          style={{ marginBottom: '20px' }}
          showIcon
          type="error"
          message={
            onlyEditLinkedEvents
              ? t`Error linking events to connection. Please try again.`
              : t`Error updating connection. Please try again.`
          }
        />
      </UnstyledCollapse>
      {!onlyEditLinkedEvents && (
        <Form.Item
          name="name"
          label={<Label isRequired>{t`Name`}</Label>}
          rules={[{ required: true, message: t`Name is required` }]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        name="events"
        label={
          <Label
            isRequired
            info={t`Assign events to use this payment gateway. Only events with payments enabled can be linked.`}
          >{t`Events`}</Label>
        }
        normalize={eventIds => eventIds.map(id => ({ id }))}
        getValueProps={events => ({
          value: events.map(event => ({ value: event.id })),
        })}
      >
        <Select
          mode="multiple"
          placeholder={t`Select`}
          loading={isAllowedEventsFetching}
          disabled={isUpdating || isAllowedEventsFetching}
          options={allowedEvents.map(event => {
            const available =
              // Disabled if a gateway is already linked to this event...
              !connectedEventIds.has(event.id) ||
              // but explicitly *enabled* if it's *this* gateway so you unlink it
              // and don't see the tooltip.
              initialValues?.events.some(e => e.id === event.id);

            return {
              label: (
                <Tooltip
                  title={!available && t`This event is already linked to a payment gateway.`}
                >
                  {event.name}
                </Tooltip>
              ),
              value: event.id,
              disabled: !available,
            };
          })}
        />
      </Form.Item>
    </Form>
  );
};

EditForm.propTypes = {
  initialValues: PropTypes.shape({
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }),
  onClose: PropTypes.func.isRequired,
  onlyEditLinkedEvents: PropTypes.bool,
};

export default EditForm;
