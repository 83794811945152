import { DeleteOutlined } from '@ant-design/icons';
import { useIsMutating } from '@tanstack/react-query';
import { Button, Modal, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';

import usePermission, { ARCHIVE_PAYMENT_GATEWAY } from '@hooks/usePermission';

import EditForm, { EDIT_CONNECTION_FORM } from './EditForm';
import { updateConnectionMutationOptions } from './queries';
import useArchiveConnections from './useArchiveConnections';

const EditModal = ({ onClose, onDelete, connection }) => {
  const { authorized } = usePermission();
  const { organizationId } = useParams();

  const isUpdating = !!useIsMutating(updateConnectionMutationOptions(organizationId));

  const handleArchiveConnections = useArchiveConnections(() => {
    onDelete();
    onClose();
  });

  return (
    <Modal
      title={t`Edit Connection`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            {authorized(ARCHIVE_PAYMENT_GATEWAY) && (
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                disabled={isUpdating}
                onClick={() => handleArchiveConnections(connection)}
              >
                {t`Delete`}
              </Button>
            )}
          </Space>
          <Space>
            <Button onClick={onClose} disabled={isUpdating}>
              {t`Cancel`}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form={EDIT_CONNECTION_FORM}
              loading={isUpdating}
            >
              {t`Save`}
            </Button>
          </Space>
        </div>
      }
      width={700}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <EditForm initialValues={connection} onClose={onClose} />
      </Space>
    </Modal>
  );
};

EditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditModal;
