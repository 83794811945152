import { Alert, Form, Radio, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import Label from 'common/components/Label';
import useConfig from 'common/hooks/useConfig';
import { getId } from 'common/state/organization';

import { EXPIRED, FAILED } from '@components/DomainStatusTag';
import FeatureContactSalesLink from '@components/FeatureContactSalesLink';
import usePermission, { CREATE_CUSTOM_DOMAINS, UPDATE_CUSTOM_DOMAINS } from '@hooks/usePermission';

import { Field } from './StyledComponents';

const KNOWLEDGE_BASE_LINK =
  'https://help.eventmobi.com/en/knowledge/can-i-change-my-app-address-or-use-a-custom-domain';

const RadioGroup = styled(Radio.Group)`
  display: block;
  margin-bottom: 8px;
`;

const RadioContainer = styled.div`
  border: 1px solid ${props => (props.$selected ? '#1890ff' : '#d9d9d9')};
  padding: 16px;
`;

const selector = createSelector(getId, organizationId => ({
  organizationId,
}));

const DomainSettings = ({ event, isDisabled }) => {
  const { organizationId } = useSelector(selector);
  const { isEnabled } = useConfig();
  const isCustomDomainConfigEnabled = isEnabled('is_custom_domain_enabled');
  const customDomainEnabled = Form.useWatch('customDomainEnabled');
  const { authorized } = usePermission();

  const manageCustomDomainsLink = (
    <Link
      key="manageCustomDomainsLink"
      to={generatePath('/organization/:organizationId/custom-domain', { organizationId })}
    >{t`manage your custom domains here.`}</Link>
  );

  const knowledgeBaseLink = (
    <Typography.Link
      key="knowledgeBaseLink"
      href={KNOWLEDGE_BASE_LINK}
      target="_blank"
    >{t`Learn about custom domains guidelines here.`}</Typography.Link>
  );

  const lowerCaseEventShortcode = event?.shortcode?.toLowerCase();
  const showDisconnectedAlert =
    customDomainEnabled && [EXPIRED, FAILED].includes(event?.customDomainStatus);

  return (
    <Field shouldUpdate label={<Label>{t`Event URL Address`}</Label>}>
      <Field name="customDomainEnabled" noStyle>
        <RadioGroup key="field" disabled={isDisabled}>
          <RadioContainer $selected={!customDomainEnabled}>
            <Radio
              value={false}
            >{t`Use default domain https://eventmobi.com/${lowerCaseEventShortcode}`}</Radio>
          </RadioContainer>
          {isCustomDomainConfigEnabled && (
            <RadioContainer $selected={customDomainEnabled}>
              {event?.customDomainName ? (
                <Radio value>
                  <Space direction="vertical" size={10}>
                    <div>
                      {t`Use custom domain`} {event?.customDomainName}/{lowerCaseEventShortcode}
                    </div>
                    {authorized(UPDATE_CUSTOM_DOMAINS) && manageCustomDomainsLink}
                    <div>{jt`Need Help? ${knowledgeBaseLink}`}</div>
                    {showDisconnectedAlert && (
                      <Alert
                        showIcon
                        type="warning"
                        message={t`Your custom domain has been disconnected. Any features or pages that used the custom domain URL can now only be accessed through the default domain URL.`}
                      />
                    )}
                  </Space>
                </Radio>
              ) : (
                <Radio value disabled>
                  <Space direction="vertical" size={10}>
                    {t`Use custom domain`}
                    {authorized(CREATE_CUSTOM_DOMAINS) && (
                      <div>{jt`No custom domains have been set up. You can ${manageCustomDomainsLink}`}</div>
                    )}
                    <div>{jt`Need Help? ${knowledgeBaseLink}`}</div>
                  </Space>
                </Radio>
              )}
            </RadioContainer>
          )}
        </RadioGroup>
      </Field>
      {!isCustomDomainConfigEnabled && (
        <Typography.Text key="contactSalesLink">
          {t`Would you like to use a custom domain for your events? `}
          <FeatureContactSalesLink featureName="Custom Domain" />
        </Typography.Text>
      )}
    </Field>
  );
};

DomainSettings.propTypes = {
  event: PropTypes.shape({
    shortcode: PropTypes.string.isRequired,
    customDomainName: PropTypes.string,
    customDomainEnabled: PropTypes.bool,
    customDomainStatus: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default DomainSettings;
